import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import { getLangTerm } from "../lang";
import WalkingIcon from "../images/svg/walking_icon.svg";
import ExternalLink from "../images/svg/external_link.svg";
import Seo from "../components/Seo";
import PurchaseButtons from "../components/PurchaseButtons";

const renderRouteContent = ({ route, content, routeLangData }) => {
    return  content.map((paragraph, i) => {
        const routeImage = route.images[i];
        return <p key={i}>
            { paragraph }
            { routeImage && <img key={`${i}-image`} className={"route-image"} src={routeImage.appsmall} alt={routeLangData.title} /> }
        </p>
    })
}

const renderRouteStop = (stopSlug, stopData, stopLangData) => {

    if (stopData.stopType != "stop") {
        return null;
    }

    return <li key={stopSlug}>
        <h4>{ stopLangData.title }</h4>
        <img src={stopData.images[0].thumbmedium} srcSet={`${stopData.images[0].thumblarge} 2x`} />
    </li>
}

const Route = ({pageContext}) => {
    const { route, lang, langData, routeStops } = pageContext;
    const { slug } = route;

    // console.log({data})
    // console.log({route})
    // console.log({routeStops})

    const routeLangData = langData[`${lang}-${slug}`];

    // console.log({routeLangData})

    const description = `VanWalks is proud to offer its ${ !route.isPremium ? "free " : "" }${routeLangData.title} Walking Tour to iOS or Android users. Download VanWalks for free today to get started.`

    return (
        <Layout 
            pageContext={pageContext}
            extraClass={"routes-container"}
        >
            <Seo title={routeLangData.title} description={description} />
            <div className="routes-hero">
                <div className="route-hero-inner">
                    <div className="text-content">
                        <h1>{routeLangData.title}</h1>
                        <div className="is-premium">
                            { route.isPremium ? <><StaticImage
                                className="premium-icon"
                                src="../images/premium_gem.png"
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt="Premium Icon"
                                placeholder={"none"}
                            />
                            <span>{ getLangTerm("premium-route", lang) }</span></> : 
                                <span>{ getLangTerm("free-route", lang) }</span> }
                        </div>
                        <div className="walking-time">
                            <WalkingIcon className="walking-icon" alt="Walking Icon" />
                            { getLangTerm("walking-time", lang) }: { route.walkingTime}
                        </div>
                        <div className="route-desc">{routeLangData.summary}</div>
                        <a href="https://map.vanwalks.ca" target="_blank" rel="noreferrer" className="try-now-link">
                            {getLangTerm("start-route-now", lang) }
                            <ExternalLink height="24" width="24" />
                        </a>
                    </div>
                    <div className="gallery">
                        <Carousel
                            dynamicHeight={true}
                            showStatus={false}
                            showThumbs={false}
                        >
                            { route.images.map((image, i) => {
                                return <div key={i}>
                                    <img className={"route-image"} src={image.appsmall} alt={routeLangData.title} />
                                </div>
                            }) }
                        </Carousel>
                    </div>
                </div>

            </div>
            <div className="sidebar-container">
                <Sidebar />
                <div className="sidebar-content">
                    <div className="route-content">
                        <PurchaseButtons route={route} />
                        <h2>{ getLangTerm("route-intro", lang).replace("||route||", routeLangData.title) }</h2>
                        { renderRouteContent({route, content: routeLangData.content, routeLangData})}
                        <PurchaseButtons route={route} />
                        <div className="route-stops">
                            <h3>Route Stops</h3>
                            <ul>
                                {
                                    route.stops.map(stopSlug => {
                                        const stopData = routeStops[stopSlug];
                                        const stopLangData = langData[`${lang}-${slug}-${stopSlug}`];
                                        return renderRouteStop(stopSlug, stopData, stopLangData, lang);
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Route;